.charts-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 30%;

  .chart-holder {
    margin: 10px;
    border: 1px solid grey;

    .chart-description {
      text-align: left;
      margin-left: 10px;
    }
  }
}
