.settings-panel {
  padding: 1ch 2ch 1ch 2ch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .settings-icon {
    cursor: pointer;
    width: 30px;
    height: 30px;

    svg {
      fill: #1997c6;
    }
  }

  .buffer-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0.3ch;

    &:hover {
      color: #ffffff;

      b i {
        border-color: transparent transparent #ffffff !important;
      }
      b:last-child {
        margin-bottom: 0.5ex;
        i {
          border-color: #ffffff transparent transparent !important;
        }
      }
    }

    p {
      color: #777777;
      font-size: 15px;
    }

    .buffer-input {
      cursor: pointer;
      width: 60px;
      height: 28px;
      margin-left: 10px;
      margin-right: 10;
      border-color: #ffffff;

      &:hover {
        color: #ffffff;
        background-color: #1997c6;
      }
    }
  }

  .type-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0.3ch;
    min-width: 120px;
    height: 30px;

    p {
      color: #777777;
      font-size: 15px;
      margin-right: 12px;
    }

    #type-input {
      color: #ffffff;
    }

    .stream-type {
      border-color: #ffffff;
    }
  }
}
