.all-settings {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1em;
  padding-left: 2ch;

  .settings-chip {
    margin: 0.3em;
  }
}
