@import "../../styles/mixins.scss";

.player-wrapper {
  height: 450px;

  @include media-breakpoint(md) {
    height: 550px;
  }

  @include media-breakpoint(lg) {
    height: 700px;
  }

  #avvpl-player-container {
    width: 95%;
    height: 100%;
    position: relative;
    margin: 0 auto;
  }
}
