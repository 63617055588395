.debug-info-wrapper {
  display: flex;
  width: 40%;
  margin-bottom: 1rem;

  .table-container {
    max-height: 600px;

    .table-cell {
      font-size: 0.8rem;
      max-width: 90px;
    }
  }

  .MuiTable-root {
    color: #ffffff !important;
    background-color: #282c34;
  }

  .MuiTableCell-head {
    color: #ffffff !important;
    background: rgba(255,255,255,0.1);
  }

  .MuiTableCell-body {
    color: #ffffff !important;
  }

  .debug-video-wrapper {
    margin: 1ch;
  }

  .debug-audio-wrapper {
    margin: 1ch;
    display: flex;
    flex-direction: column;
  }

  .current-bitrate {
    color: #ffffff !important;
  }

  .other-bitrate {
    color: #808080 !important;
  }

  .current-segment {
    color: #ffffff !important;
  }

  .other-segment {
    color: #808080 !important;
  }

  .not-live-edge {
    color: #ff0000 !important;
  }

  .live-edge {
    color: #ffffff !important;
  }

  .legend-table-container {
    margin-top: 10%;

    .current-line {
      border-color: #ffffff;
    }

    .target-line {
      border-color: #008a5d;
    }

    .timeframe-line {
      border-color: #f09c9c;
    }
  }
}
