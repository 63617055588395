.stream-load-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 2ch;

  .load-button {
    margin-right: 2ch;
  }

  .url-input-wrapper {
    width: 90%;

    fieldset {
      border-color: #777777;
      &:hover {
        border-color: #777777;
      }
    }
  }
}
