.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  overflow: auto;

  #avvpl-player-container {
    display: flex;
    width: 100%;
  }

  .input-holder {
    display: flex;
    justify-content: space-between;
  }

  .top-section {
    display: flex;
    justify-content: space-between;
  }
}
